export const Locations = [
  {
    title: 'Registered Office, India',
    paragraph:
      'iTips Private Limited\n WeWork Galaxy, 43,\n Residency Rd, Shanthala Nagar, Ashok Nagar,\n Bengaluru, Karnataka, India 560025',
    locationURL:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9764100503526!2d77.60618651072411!3d12.973360587289362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1680b3bd0f7b%3A0x5466660c68f2146b!2sWeWork!5e0!3m2!1sja!2sjp!4v1685065992088!5m2!1sja!2sjp',
    id: 'india',
  },
  {
    title: ' Factory & Skill Training Centre, India',
    paragraph:
      'iTips Private Limited\n No 90, Sipcot Industrial complex, Phase – 1,\n Hosur, Tamil Nadu, India 635126',
    locationURL:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15567.398366247855!2d77.85016337597403!3d12.723233513029074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae776dcb134565%3A0x7daaf1286256e044!2sSIPCOT%20Industrial%20Complex%2C%20Sipcot%20Ph%203rd%20Cross%20Rd%2C%20Sipcot%20Phase%20II%20Industrial%20Complex%2C%20Sipcot%20Ph%202%2C%20Moranapalli%2C%20Tamil%20Nadu%20635109%20%E3%82%A4%E3%83%B3%E3%83%89!5e0!3m2!1sja!2sjp!4v1685066791974!5m2!1sja!2sjp',
    id: 'india',
  },
  {
    title: 'Nagoya, Japan Office',
    paragraph: '1-2-32, Tsurumai,\nShowa-ku, Nagoya-shi, Aichi, Japan',
    imageUrl: '/nagoya_office.webp',
    locationURL:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.1538109866906!2d136.91845179999999!3d35.1527846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600371006969dc3f%3A0x5fbab00be497ca28!2sSTATION%20Ai!5e0!3m2!1sja!2sjp!4v1729847925951!5m2!1sja!2sjp',
    id: 'japan',
  },
];
